import { formatDate } from '../../dateUtils'
import { fetchRequest } from '../helpers'
import {
  mapCartObject,
  mapOrderList,
  mapRegularDeliveries,
  mapUserObject,
} from './mapper'

/**
 * Mapping for the sub-paths of the API endpoints.
 *
 * @type {Object}
 */
const apiEndpoints = {
  GETUSERDATA: 'getUserData',
  ADDTOCART: 'addToCart',
  NEWPASSWORD: 'newPassword',
  FORGOTPASSWORD: 'forgotpassword',
  RESETPASSWORD: 'resetpassword',
  REGISTERACTION: 'register',
  LOGIN: 'login',
  LOGOUT: 'logout',
  CONFIRM_REGISTRATION: 'confirm',
  KLARNA: 'klarna',
  POSTCOUPON: 'postCoupon',
  LOADREVIEWS: 'reviews',
  SUCCESS: 'success',
  CANCELORDER: 'cancelOrder',
  PREORDER_ACTION: 'preOrder',
  SET_PAYMENT_ADDRESS_CORRECTION: 'setPaymentAddressCorrection',
  SET_PAYMENT_REVIEW: 'paypalExpressReview',
  GET_PAYMENT_ADDRESS_CORRECTION: 'getPaymentAddressCorrection',
  ORDER_DATA_FOR_RATINGS: 'getOrderDataForRatings',
  GET_ORDER_LIST: 'getOrderList',
  ORDER_DETAILS: 'getOrderDetails',
  GET_CUSTOMER_ACCOUNT_DATA: 'getCustomerAccountData',
  SET_CUSTOMER_ACCOUNT_DATA: 'setCustomerAccountData',
  GET_REORDER_PRODUCT_IDS: 'getCustomerOrderedProducts',
  GET_SUBSCRIPTION_LIST: 'subscriptionList',
  GET_SUBSCRIPTION_DETAILS: 'subscriptionDetails',
  UPDATE_SUBSCRIPTION_DATA: 'subscriptionUpdate',
  SUBMIT_NEWSLETTER: 'submitNewsletter',
  CONFIRM_NEWSLETTER: 'confirmNewsletter',
  UNSUBSCRIBE_NEWSLETTER: 'unsubscribeNewsletter',
  OPTOUT_NEWSLETTER: 'optoutNewsletter',
  SUBMIT_LEADFORM: 'submitLeadform',
  GET_CART_DETAILS: 'getCartDetails',
  SET_CART_ITEM_ABO_STATUS: 'updateQuoteItemAboStatus',
  SET_CART_ITEM_QTY: 'updateQuoteItemQty',
  REMOVE_ABO_COUPON: 'removeAboCoupon',
  UPDATE_SHIPPING_OPTIONS: 'updateShippingOption',
  ADD_REORDER_ITEMS: 'addReorderItems',
}

/**
 * Provide functions to access the Magento-API.
 * @class
 */
export default class MagentoApi {
  /**
   * Wrapper to perform a GET request.
   *
   * @param {string} endpoint
   * @returns {Promise<*>}
   */
  doGet = async (endpoint) => {
    return fetchRequest(endpoint, 'GET', null, apiEndpoints)
  }

  /**
   * Wrapper to perform a POST request.
   *
   * @param {string} endpoint
   * @param {Object} body
   * @returns {Promise<*>}
   */
  doPost = async (endpoint, body = {}) => {
    return fetchRequest(endpoint, 'POST', JSON.stringify(body), apiEndpoints)
  }

  ///////////////////////////////////////////////////////////////////////////
  //// User actions ////

  getUserData = async () => {
    return this.doGet(apiEndpoints.GETUSERDATA).then(mapUserObject)
  }

  newPassword = async (body) => {
    return this.doPost(apiEndpoints.NEWPASSWORD, body)
  }

  forgotPassword = async ({ email }) => {
    return this.doPost(apiEndpoints.FORGOTPASSWORD, {
      username: email,
    })
  }

  resetPassword = async (params) => {
    const allowedParams = ['token', 'id']
    const postData = {}

    allowedParams.forEach((param) => {
      if (typeof params[param] === 'undefined') {
        throw Error(`Required parameter "${param}" does not exist`)
      }
      postData[param] = params[param]
    })

    return this.doPost(apiEndpoints.RESETPASSWORD, postData).then(
      ({ status, ...rest }) => ({
        status: status?.toLowerCase(),
        ...rest,
      })
    )
  }

  registerUser = async ({ firstName, lastName, email, password }) => {
    return this.doPost(apiEndpoints.REGISTERACTION, {
      firstname: firstName,
      lastname: lastName,
      username: email,
      password,
    })
  }

  loginUser = async ({ email, password }) => {
    const response = await this.doPost(apiEndpoints.LOGIN, {
      username: email,
      password,
    })
    const userData = await this.getUserData()
    // Restore the original login response status/message
    userData.message = response.status !== 'ok' ? response.message : null
    userData.status = response.status
    return userData
  }

  logoutUser = async () => {
    return this.doGet(apiEndpoints.LOGOUT)
  }

  confirmRegistration = async (params) => {
    const allowedParams = ['back_url', 'key', 'id']
    const paramKeys = Object.keys(params)

    const postData = {}
    allowedParams.map((param) => {
      if (!paramKeys.includes(param)) {
        throw Error(`Required parameter "${param}" does not exist`)
      }
      postData[param] = params[param]
    })

    return this.doPost(apiEndpoints.CONFIRM_REGISTRATION, postData).then(
      ({ status, ...rest }) => ({
        status: status?.toLowerCase(),
        ...rest,
      })
    )
  }

  getCustomerAccountData = async () => {
    return this.doPost(apiEndpoints.GET_CUSTOMER_ACCOUNT_DATA).then(
      ({ status, ...rest }) => ({
        status: status?.toLowerCase(),
        ...rest,
      })
    )
  }

  setCustomerAccountData = async ({
    firstName,
    lastName,
    changePassword,
    currentPassword,
    ...body
  }) => {
    const response = await this.doPost(apiEndpoints.SET_CUSTOMER_ACCOUNT_DATA, {
      firstname: firstName,
      lastname: lastName,
      dob: '', // Empty 'dob' parameter needed by the API for some reason to make removing the birthday work
      change_password: changePassword,
      current_password: currentPassword,
      ...body,
    }).then(({ status, ...rest }) => ({
      status: status?.toLowerCase(),
      ...rest,
    }))
    const customerData = await this.getCustomerAccountData()
    // Replace Customer status/message with the original requests of setCustomerAccountData response
    customerData.message = response.status !== 'ok' ? response.message : null
    customerData.status = response.status
    return customerData
  }

  //// ??? actions ////

  getSuccessData = async (orderId) => {
    return this.doPost(apiEndpoints.SUCCESS, { order_id: orderId })
  }

  paymentReview = async () => {
    return this.doPost(apiEndpoints.SET_PAYMENT_REVIEW, {})
  }

  getPaymentAddressCorrection = async () => {
    return this.doGet(apiEndpoints.GET_PAYMENT_ADDRESS_CORRECTION)
  }

  setPaymentAddressCorrection = async ({
    billingName,
    billingStreet,
    shippingName,
    shippingStreet,
  }) => {
    return this.doPost(apiEndpoints.SET_PAYMENT_ADDRESS_CORRECTION, {
      billing: {
        name: billingName,
        street: billingStreet,
      },
      shipping: {
        name: shippingName,
        street: shippingStreet,
      },
    })
  }

  getOrderDataForRatings = async (body) => {
    return this.doPost(apiEndpoints.ORDER_DATA_FOR_RATINGS, body)
  }

  //// Order actions ////

  cancelOrder = async (incrementId, encryptionKey) => {
    return this.doPost(apiEndpoints.CANCELORDER, {
      increment_id: incrementId,
      encryption_key: encryptionKey,
    })
  }

  addReorderItems = async (orderId) => {
    return this.doPost(apiEndpoints.ADD_REORDER_ITEMS, {
      order_id: orderId,
    })
  }

  getOrderList = async ({ page = 0, limit = 10 }) => {
    return this.doPost(apiEndpoints.GET_ORDER_LIST, {
      page: page + 1, // PHP starts at index 1
      limit: limit,
    }).then(mapOrderList)
  }

  getOrderDetails = async ({ orderId }) => {
    return this.doPost(apiEndpoints.ORDER_DETAILS, {
      orderId,
    }).then(({ status, ...rest }) => ({
      status: status.toLowerCase(),
      ...rest,
    }))
  }

  getReorderProductList = async () => {
    return this.doPost(apiEndpoints.GET_REORDER_PRODUCT_IDS, {})
  }

  getSubscriptionDetails = async () => {
    return this.doPost(apiEndpoints.GET_SUBSCRIPTION_DETAILS, {}).then(
      mapRegularDeliveries
    )
  }

  updateSubscriptionData = async (body) => {
    const {
      id,
      status,
      label,
      nextDeliveryDate,
      deliveryInterval,
      deliveryAddress: { firstName, lastName, ...rest },
    } = body
    return this.doPost(apiEndpoints.UPDATE_SUBSCRIPTION_DATA, {
      id,
      status: status === 'canceled' ? 'cancelled' : status,
      next_delivery_date: formatDate(nextDeliveryDate, 'dd.MM.yyyy', '.'), // Fixed format for Magento
      delivery_interval: deliveryInterval.toString(),
      name: label,
      deliveryAddress: {
        firstname: firstName,
        lastname: lastName,
        ...rest,
      },
    })
  }

  //// Newsletter actions ////

  submitNewsletterForm = async (body) => {
    return this.doPost(apiEndpoints.SUBMIT_NEWSLETTER, body)
  }

  confirmNewsletter = async (id, token) => {
    return this.doPost(apiEndpoints.CONFIRM_NEWSLETTER, {
      id: id,
      token: token,
    })
  }

  unsubscribeNewsletter = async (id, token, utmCampaign) => {
    return this.doPost(apiEndpoints.UNSUBSCRIBE_NEWSLETTER, {
      id,
      token,
      utmCampaign,
    })
  }

  optoutNewsletter = async (email, utmCampaign) => {
    return this.doPost(apiEndpoints.OPTOUT_NEWSLETTER, {
      email: email,
      utmCampaign: utmCampaign,
    })
  }

  submitLeadform = async (body) => {
    return this.doPost(apiEndpoints.SUBMIT_LEADFORM, body)
  }

  //// Cart actions ////

  preorderProduct = async ({ productId, email, token }) => {
    return this.doPost(apiEndpoints.PREORDER_ACTION, {
      productId,
      email,
      token,
    })
  }

  getCartData = async () => {
    return this.doGet(apiEndpoints.GET_CART_DETAILS).then(mapCartObject)
  }

  addToCart = async ({
    productData,
    qty,
    selectedOptions,
    isAboEnabled = false,
    couponCode = '',
  }) => {
    const response = await this.doPost(apiEndpoints.ADDTOCART, {
      productId: productData.id,
      qty,
      bundle_option: selectedOptions,
      isAbo: isAboEnabled,
      couponCode,
    })

    const cartData = await this.getCartData()
    // Replace cart status/message with the original requests data
    cartData.message = response.status !== 'ok' ? response.message : null
    cartData.status = response.status
    return cartData
  }

  setCartItemQty = async ({ cartProduct, qty }) => {
    const response = await this.doPost(apiEndpoints.SET_CART_ITEM_QTY, {
      itemId: cartProduct.id,
      qty,
    })
    const cartData = await this.getCartData()
    // Replace cart status/message with the original requests data
    cartData.message = response.status !== 'ok' ? response.message : null
    cartData.status = response.status
    return cartData
  }

  setCouponCode = async ({ couponCode }) => {
    const response = await this.doPost(apiEndpoints.POSTCOUPON, {
      coupon: couponCode,
    })
    const cartData = await this.getCartData()
    // Replace cart status/message with the original requests data
    cartData.message = response.message
    cartData.status = response.status
    return cartData
  }

  removeAboCoupon = async () => {
    const response = await this.doPost(apiEndpoints.REMOVE_ABO_COUPON, {})
    const cartData = await this.getCartData()
    // Replace cart status/message with the original requests data
    cartData.message = response.status !== 'ok' ? response.message : null
    cartData.status = response.status
    return cartData
  }

  setCartItemAboStatus = async ({ cartProduct, isAboEnabled }) => {
    const response = await this.doPost(apiEndpoints.SET_CART_ITEM_ABO_STATUS, {
      itemProductId: cartProduct.aboId,
      isAboItem: isAboEnabled,
    })
    const cartData = await this.getCartData()
    // Replace cart status/message with the original requests data
    cartData.message = response.status !== 'ok' ? response.message : null
    cartData.status = response.status
    return cartData
  }

  setShippingOptions = async (body) => {
    const response = await this.doPost(
      apiEndpoints.UPDATE_SHIPPING_OPTIONS,
      body
    )
    const cartData = await this.getCartData()
    // Replace cart status/message with the original requests data
    cartData.message = response.status !== 'ok' ? response.message : null
    cartData.status = response.status
    return cartData
  }
}
